import React, { useState } from 'react';
import { Button, Typography, Grid, Card, CardContent } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { FilterContext } from '../FilterContext';
import { useContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const SubscriptionPage = ({ }) => {
    const theme = createTheme();

    theme.typography.h4 = {
        fontFamily: 'Roboto',
        color: 'white'
    };

    theme.typography.h5 = {
        fontFamily: 'Roboto',
        color: 'white'
    };

    theme.typography.body1 = {
        fontFamily: 'Roboto',
        color: 'white'
    };

    const navigate = useNavigate();
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const { checkoutDetails, setCheckoutDetails } = useContext(FilterContext);

    const subscriptionTypes = [
        {
            id: 1,
            name: 'Basic',
            description: 'Our 3 Month plan, perfect for small businesses.',
            price: '$69',
            amount: 69,
            term: '3 Months',
        },
        {
            id: 2,
            name: 'Pro',
            description: 'Our 6 Month plan, ideal for growing businesses.',
            price: '$89',
            amount: 89,
            term: '6 Months',
        },
        {
            id: 3,
            name: 'Enterprise',
            description: 'Our 12 Month plan, designed for large businesses.',
            price: '$99',
            amount: 99,
            term: '12 Months',
        },
    ];

    const handleSelectSubscription = (subscription) => {
        setSelectedSubscription(subscription);
        setCheckoutDetails({
            ...checkoutDetails,
            subscription: subscription.name,
            amount: subscription.amount,
            term: subscription.term,
        });
    };

    const handleContinue = (event) => {

        navigate('/Password', { state: { checkoutDetails } });
    };

    return (

        <ThemeProvider theme={theme}>

            <Grid container spacing={2} justify="center">
                <Grid item xs={12}>
                    <Typography variant="h4" align="center">
                        Choose Your Subscription
                    </Typography>
                </Grid>
                {subscriptionTypes.map((subscription) => (
                    <Grid item xs={4} key={subscription.id}>
                        <Card sx={{ bgcolor: 'black' }}>
                            <CardContent>
                                <Typography variant="h5">{subscription.name}</Typography>
                                <Typography variant="body1">{subscription.description}</Typography>
                                <Typography variant="body1">{subscription.price}</Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSelectSubscription(subscription)}
                                >
                                    Select
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ bgcolor: 'white', color: 'black' }}
                        onClick={handleContinue}
                        disabled={!selectedSubscription}
                    >
                        Continue
                    </Button>
                </Grid>
            </Grid>

        </ThemeProvider>
    );
};

export default SubscriptionPage;