import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ClippedDrawer() {

    const faqs = [
        {
            question: 'Who is The Batman?',
            answer: 'Antonio.'
        },
    ];

    return (

        <Box component="main"

            sx={{ bgcolor: 'black' }}
        >

            <Container maxWidth="md">

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                    <Grid item xs={12}>

                        <Box
                            height="1vh"
                        ></Box>

                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                            <Grid item xs={12}>

                                <Box sx={{ height: 'auto', width: '100%' }}>
                                    <Typography variant="h6" align="left" sx={{ color: 'white' }}>
                                        Frequently Asked Questions
                                    </Typography>

                                    <Box
                                        height="1vh"
                                    ></Box>

                                    {faqs.map((faq, index) => (
                                        <Accordion key={index} sx={{ bgcolor: 'black' }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography sx={{ color: 'white' }}>{faq.question}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ bgcolor: 'black' }}>
                                                <Typography sx={{ color: 'white' }}>{faq.answer}</Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}

                                </Box>

                                <Box
                                    height="1vh"
                                ></Box>

                            </Grid>

                        </Grid>

                        <Box
                            height="5vh"
                        ></Box>

                    </Grid>

                </Grid>

            </Container>

        </Box>

    );
}