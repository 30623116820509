import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'; // Add Grid import

export default function DashboardTiles() {
    return (
        <Box component="main" sx={{ bgcolor: 'black' }}>
            <Container maxWidth="md">
                <Typography variant="h4" align="left">
                    Dashboard
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <Typography variant="h6" align="left">
                            Inventory
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6" align="left">
                            Purchases
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <Typography variant="h6" align="left">
                            Customers
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6" align="left">
                            Expenses
                        </Typography>
                    </Grid>
                </Grid>

                <Typography variant="h6" align="left">
                    Proposals
                </Typography>

                <Typography variant="h6" align="left">
                    Work Orders
                </Typography>

                <Typography variant="h6" align="left">
                    Invoices
                </Typography>

            </Container>
        </Box>
    );
}