import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Grid, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { FilterContext } from '../FilterContext';
import { useContext } from 'react';

const Login = ({ isSignedIn, setIsSignedIn }) => {

    const navigate = useNavigate();
    const { userDetails, setUserDetails } = useContext(FilterContext);
    const [values, setValues] = useState({
        username: '',
        password: '',
    });
    const [error, setError] = useState(null);
    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('http://localhost:9000/api/auth/login', values);
            const { accessToken, refreshToken } = response.data;
            if (accessToken) {
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);
                try {
                    const userDetailsResponse = await axios.get(`http://localhost:9000/resource/user-details/${values.username}`);
                    const userDetailsData = userDetailsResponse.data;

                    const tokenVerification = await axios.post(`http://localhost:8080/api/auth/verification`, accessToken);
                    console.log(tokenVerification);

                    localStorage.setItem('userDetails', JSON.stringify(userDetailsData));
                    setUserDetails(userDetailsData);

                    setIsSignedIn(true);
                    navigate('/Dashboard');

                } catch (error) {
                    console.error('Error fetching user details:', error);
                }
            } else {
                setError('Invalid credentials');
            }
        } catch (error) {
            setIsSignedIn(false);
            setError('Invalid credentials');
        }
    };

    const handleCreateAccount = () => {
        navigate('/CreateAccount');
    };

    return (
        <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
            <Grid item>
                <Typography variant="h4" sx={{ color: 'white' }}>Sign in</Typography>
            </Grid>
            <Grid item>
                <TextField
                    label="Username"
                    variant="outlined"
                    name="username"
                    value={values.username}
                    onChange={handleChange}
                    aria-label="Username"
                    sx={{
                        input: { color: 'blue' },
                        label: { color: 'red' },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'red',
                            },
                            '&:hover fieldset': {
                                borderColor: 'blue',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'blue',
                            },
                            '& .MuiInputBase-input': {
                                backgroundColor: 'black',
                            },
                        },
                    }}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                />
            </Grid>
            <Grid item>
                <TextField
                    label="Password"
                    variant="outlined"
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    aria-label="Password"
                    sx={{
                        input: { color: 'blue' },
                        label: { color: 'red' },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'red',
                            },
                            '&:hover fieldset': {
                                borderColor: 'blue',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'blue',
                            },
                            '& .MuiInputBase-input': {
                                backgroundColor: 'black',
                            },
                        },
                    }}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                />
            </Grid>
            <Grid item>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </Grid>

            <Box height="3vh"></Box>

            <Divider />

            <Grid item>
                <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    type="submit"
                    onClick={handleCreateAccount}
                >
                    Create Account
                </Button>
            </Grid>

            {error && (
                <Grid item>
                    <Typography variant="body1" color="error">
                        {error}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
}

export default Login;