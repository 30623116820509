import React, { useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { FilterContext } from '../FilterContext';
import { useContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const CreateAccount = ({ }) => {

    const theme = createTheme();

    theme.typography.h2 = {
        fontFamily: 'Roboto',
        color: 'white',
    };

    theme.typography.body1 = {
        fontFamily: 'Roboto',
        color: 'white',
    };

    const { checkoutDetails, setCheckoutDetails } = useContext(FilterContext);
    const navigate = useNavigate();

    const handleClick = (event) => {

        navigate('/Password', { state: { checkoutDetails } });
    };

    return (

        <ThemeProvider theme={theme}>

            <Box
                sx={{ bgcolor: 'black' }}
            >

                <Container maxWidth="md">

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                        <Grid item xs={12}>

                            <Box
                                height="1vh"
                            ></Box>

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                <Grid item xs={12}>

                                    <Box sx={{ height: 'auto', width: '100%' }}>
                                        <Typography variant="h2" align="left">
                                            Create Account
                                        </Typography>
                                        <Box height="1vh" />
                                        <Typography variant="body1" align="left">
                                            Email
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="email"
                                            label="Email"
                                            variant="outlined"
                                            value={checkoutDetails.email}
                                            onChange={(event) => setCheckoutDetails({ ...checkoutDetails, email: event.target.value })}
                                        />
                                        <Box height="1vh" />
                                        <Typography variant="body1" align="left">
                                            Phone
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="phone"
                                            label="Phone"
                                            variant="outlined"
                                            value={checkoutDetails.phone}
                                            onChange={(event) => setCheckoutDetails({ ...checkoutDetails, phone: event.target.value })}
                                        />
                                        <Box height="1vh" />
                                        <Typography variant="body1" align="left">
                                            Country
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="country"
                                            label="Country"
                                            variant="outlined"
                                            value={checkoutDetails.country}
                                            onChange={(event) => setCheckoutDetails({ ...checkoutDetails, country: event.target.value })}
                                        />
                                        <Box height="1vh" />
                                        <Typography variant="body1" align="left">
                                            Date of Birth
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="dob"
                                            label="Date of Birth"
                                            variant="outlined"
                                            value={checkoutDetails.dob}
                                            onChange={(event) => setCheckoutDetails({ ...checkoutDetails, dob: event.target.value })}
                                        />
                                        <Box height="1vh" />
                                        <Typography variant="body1" align="left">
                                            First Name
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            variant="outlined"
                                            value={checkoutDetails.firstName}
                                            onChange={(event) => setCheckoutDetails({ ...checkoutDetails, firstName: event.target.value })}
                                        />
                                        <Box height="1vh" />
                                        <Typography variant="body1" align="left">
                                            Last Name
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            variant="outlined"
                                            value={checkoutDetails.lastName}
                                            onChange={(event) => setCheckoutDetails({ ...checkoutDetails, lastName: event.target.value })}
                                        />
                                        <Box height="1vh" />
                                        <Typography variant="body1" align="left">
                                            Middle Name
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="middleName"
                                            label="Middle Name"
                                            variant="outlined"
                                            value={checkoutDetails.middleName}
                                            onChange={(event) => setCheckoutDetails({ ...checkoutDetails, middleName: event.target.value })}
                                        />
                                        <Box height="1vh" />
                                        <Typography variant="body1" align="left">
                                            Suffix
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="suffix"
                                            label="Suffix"
                                            variant="outlined"
                                            value={checkoutDetails.suffix}
                                            onChange={(event) => setCheckoutDetails({ ...checkoutDetails, suffix: event.target.value })}
                                        />
                                        <Box height="1vh" />
                                        <Typography variant="body1" align="left">
                                            Address
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="address"
                                            label="Address"
                                            variant="outlined"
                                            value={checkoutDetails.address}
                                            onChange={(event) => setCheckoutDetails({ ...checkoutDetails, address: event.target.value })}
                                        />
                                        <Box height="1vh" />
                                        <Typography variant="body1" align="left">
                                            City
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="city"
                                            label="City"
                                            variant="outlined"
                                            value={checkoutDetails.city}
                                            onChange={(event) => setCheckoutDetails({ ...checkoutDetails, city: event.target.value })}
                                        />
                                        <Box height="1vh" />
                                        <Typography variant="body1" align="left">
                                            State
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="state"
                                            label="State"
                                            variant="outlined"
                                            value={checkoutDetails.state}
                                            onChange={(event) => setCheckoutDetails({ ...checkoutDetails, state: event.target.value })}
                                        />
                                        <Box height="1vh" />
                                        <Typography variant="body1" align="left">
                                            Zip Code
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="zip"
                                            label="Zip Code"
                                            variant="outlined"
                                            value={checkoutDetails.zip}
                                            onChange={(event) => setCheckoutDetails({ ...checkoutDetails, zip: event.target.value })}
                                        />
                                        <Box height="1vh" />
                                        <Typography variant="body1" align="left">
                                            Company
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="company"
                                            label="Company"
                                            variant="outlined"
                                            value={checkoutDetails.company}
                                            onChange={(event) => setCheckoutDetails({ ...checkoutDetails, company: event.target.value })}
                                        />

                                        <Box
                                            height="2vh"
                                        ></Box>

                                        <Button onClick={() => handleClick()} variant="contained" type="submit">
                                            Next
                                        </Button>
                                    </Box>

                                    <Box
                                        height="1vh"
                                    ></Box>

                                </Grid>

                            </Grid>

                            <Box
                                height="5vh"
                            ></Box>

                        </Grid>

                    </Grid>

                </Container>

            </Box>

        </ThemeProvider>
    );
};

export default CreateAccount;