import './App.css';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TopBar from './components/TopBar.tsx';
import Login from './components/Login';
import { FilterContext } from './FilterContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Faq from './components/Faq';
import About from './components/About';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Cookies from './components/Cookies';
import Dashboard from './components/Dashboard';
import Home from './components/Home';
import CreateAccount from './components/CreateAccount';
import Subscriptions from './components/Subscriptions';
import Password from './components/Password';
import Checkout from './components/Checkout';

//routing
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

const App = () => {

    const [isSignedIn, setIsSignedIn] = React.useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [checkoutDetails, setCheckoutDetails] = useState({});

    useEffect(() => {
        const checkSignedIn = () => {
            const accessToken = localStorage.getItem('accessToken');
            if (accessToken) {
                setIsSignedIn(true);
                const userDetails = localStorage.getItem('userDetails');
                if (userDetails) {
                    setUserDetails(JSON.parse(userDetails)); // retrieve userDetails from localStorage
                }

            } else {
                setIsSignedIn(false);
            }
        };
        checkSignedIn();
    }, []);

    return (

        <GoogleOAuthProvider clientId="<your_client_id>">

            <Router>

                <div className="App" style={{ backgroundColor: 'white', height: '100vh' }}>
                    <FilterContext.Provider value={{

                        isSignedIn, setIsSignedIn,
                        userDetails, setUserDetails,
                        checkoutDetails, setCheckoutDetails,
                    }}>

                        <TopBar
                            isSignedIn={isSignedIn}
                            setIsSignedIn={setIsSignedIn}
                        />

                        <Box
                            height="10vh"
                        ></Box>

                        <Routes>

                            <Route path="/" element={
                                isSignedIn ? <Home /> : <Navigate to="/Home" replace />
                            } />

                            <Route path="/Login" element={
                                isSignedIn ? <Navigate to="/Home" replace /> : <Login
                                    isSignedIn={isSignedIn}
                                    setIsSignedIn={setIsSignedIn}
                                />}
                            />

                            <Route path="/Home" element={<Home />} />
                            <Route path="/Login" element={<Login />} />
                            <Route path="/Faq" element={<Faq />} />
                            <Route path="/About" element={<About />} />
                            <Route path="/Terms" element={<Terms />} />
                            <Route path="/Cookies" element={<Cookies />} />
                            <Route path="/Privacy" element={<Privacy />} />
                            <Route path="/Home" element={<Home />} />
                            <Route path="/CreateAccount" element={<CreateAccount />} />
                            <Route path="/Subscriptions" element={<Subscriptions />} />
                            <Route path="/Password" element={<Password />} />
                            <Route path="/Checkout" element={<Checkout />} />

                            {isSignedIn ? (
                                <>
                                    <Route path="/Dashboard" element={<Dashboard />} />

                                </>
                            ) : (
                                <Route path="*" element={<Navigate to="/Login" replace />} />
                            )}
                        </Routes>

                    </FilterContext.Provider>

                </div>

            </Router>

        </GoogleOAuthProvider>
    );
};

export default App;