import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { FilterContext } from '../FilterContext';
import { useContext } from 'react';
import Container from '@mui/material/Container';
import ConvertToSubCurrency from '../lib/ConvertToSubCurrency';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

const Checkout = () => {

    const theme = createTheme();
    const navigate = useNavigate();

    theme.typography.h4 = {
        fontSize: '1.2rem',
        '@media (min-width: 300px)': {
            fontSize: '2rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem',
        },
        fontFamily: 'Roboto',
        color: 'white',
    };

    theme.typography.h6 = {
        fontSize: '1.2rem',
        '@media (min-width: 300px)': {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        },
        fontFamily: 'Roboto',
        color: 'white',
    };

    theme.typography.body1 = {
        fontSize: '1.2rem',
        '@media (min-width: 300px)': {
            fontSize: '1rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        },
        fontFamily: 'Roboto',
        color: 'white',
    };

    const [error, setError] = useState(null);

    const { checkoutDetails, setCheckoutDetails } = useContext(FilterContext);
    const [clientSecret, setClientSecret] = useState("");
    const email = checkoutDetails.email;
    const phone = checkoutDetails.phone;
    const dob = checkoutDetails.dob;
    const firstName = checkoutDetails.firstName;
    const lastName = checkoutDetails.lastName;
    const middleName = checkoutDetails.middleName;
    const suffix = checkoutDetails.suffix;
    const address = checkoutDetails.address;
    const city = checkoutDetails.city;
    const state = checkoutDetails.state;
    const zip = checkoutDetails.zip;
    const country = checkoutDetails.country;
    const password = checkoutDetails.password;
    const amount = checkoutDetails.amount;

    const convertedAmount = ConvertToSubCurrency(amount); // Convert amount to sub-currency

    const [values, setValues] = useState({
        newAmount: convertedAmount,
    });

    useEffect(() => {
        const fetchClientSecret = async () => {
            try {

                const response = await axios.post('http://localhost:9000/api/stripe/client-secret', values);

                const clientSecret = response.data.clientSecret;
                setClientSecret(clientSecret); // Update the clientSecret state

            } catch (error) {
                setError('Failed to retrieve client secret from backend API');
            }
        };
        fetchClientSecret();
    }, [amount]); // Add amount as a dependency to re-run the effect when amount changes

    const handleCreateAccount = () => {
        navigate('/');
    };

    return (

        <ThemeProvider theme={theme}>

            <Box component="main"
                sx={{
                    bgcolor: 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >

                <Container maxWidth="md">

                    <Box>

                        <Box
                            height="3vh"
                        ></Box>

                        <Typography variant="h4" align="left">
                            My Information
                        </Typography>

                        <Box
                            height="3vh"
                        ></Box>

                        <Divider />

                        <Box>

                            <Box sx={{ height: 'auto', width: '100%' }}>

                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="left">
                                            Email:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" align="left">
                                            {email}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="left">
                                            Phone:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" align="left">
                                            {phone}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="left">
                                            Date of Birth:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" align="left">
                                            {dob}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="left">
                                            First Name:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" align="left">
                                            {firstName}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="left">
                                            Last Name:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" align="left">
                                            {lastName}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="left">
                                            Middle Name:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" align="left">
                                            {middleName}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="left">
                                            Suffix:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" align="left">
                                            {suffix}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="left">
                                            Address:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" align="left">
                                            {address}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="left">
                                            City:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" align="left">
                                            {city}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="left">
                                            State:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" align="left">
                                            {state}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="left">
                                            Zip:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" align="left">
                                            {zip}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="left">
                                            Country:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" align="left">
                                            {country}
                                        </Typography>
                                    </Grid>

                                </Grid>

                                <Box
                                    height="3vh"
                                ></Box>

                                <Divider />

                                <Typography variant="h4" align="left">
                                    Password
                                </Typography>

                                <Typography variant="body1" align="left">
                                    {password ? [...Array(password.length)].map(() => '*').join('') : ''}
                                </Typography>

                                <Box
                                    height="3vh"
                                ></Box>

                                <FormGroup>
                                    <FormControlLabel
                                        sx={{ color: 'white' }}
                                        control={
                                            <Checkbox
                                                sx={{ color: 'white' }}
                                                checked={checkoutDetails.termsAccepted}
                                                onChange={(event) => setCheckoutDetails({ ...checkoutDetails, termsAccepted: event.target.checked })}
                                            />
                                        }
                                        label="I accept the terms and conditions"
                                    />
                                </FormGroup>

                                <Box
                                    height="3vh"
                                ></Box>

                                <Divider />

                            </Box>

                        </Box>

                        <Box
                            height="3vh"
                        ></Box>

                        <Button
                            fullWidth
                            variant="outlined"
                            color="secondary"
                            type="submit"
                            onClick={handleCreateAccount}
                        >
                            Create Account
                        </Button>

                        <Divider />

                        <Box
                            height="3vh"
                        ></Box>

                    </Box>

                </Container>

            </Box>

        </ThemeProvider>
    );
};

export default Checkout;