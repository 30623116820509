import React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useNavigate, useLocation } from "react-router-dom";

const RightDrawer = ({ isSignedIn }) => {

    const navigate = useNavigate();
    const location = useLocation();

    const handleHomeClick = () => {
        navigate('/Home');
    };

    const handleAboutClick = () => {
        navigate('/About');
    };

    const handleFaqClick = () => {
        navigate('/Faq');
    };

    const handleSignInClick = () => {
        navigate('/login');
    };

    return (
        <Box
            sx={{
                height: '100vh',
                bgcolor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >

            <Container maxWidth="lg">
                <Box
                    sx={{ bgcolor: 'white' }}
                    height="3vh"
                ></Box>

                <Divider sx={{ borderColor: 'white' }} />

                <Box
                    sx={{ bgcolor: 'white' }}
                    height="1vh"
                ></Box>

                <Box sx={{ bgcolor: 'white', textAlign: 'center' }}>
                    <form>

                        <Typography variant="h4" align="center" sx={{ display: 'block', fontWeight: 'bold', color: 'blue', fontSize: { xs: 30, sm: 30, md: 36, lg: 18 } }}>
                            Menu
                        </Typography>

                        <Typography variant="body1" align="center" sx={{ display: 'block', cursor: 'pointer', color: location.pathname === '/Home' ? 'cyan' : 'grey', fontSize: { xs: 25, sm: 22, md: 26, lg: 14 } }} onClick={handleHomeClick}>
                            Home
                        </Typography>

                        <Typography variant="body1" align="center" sx={{ display: 'block', cursor: 'pointer', color: location.pathname === '/About' ? 'cyan' : 'grey', fontSize: { xs: 25, sm: 22, md: 26, lg: 14 } }} onClick={handleAboutClick}>
                            About
                        </Typography>

                        <Typography variant="body1" align="center" sx={{ display: 'block', cursor: 'pointer', color: location.pathname === '/Faq' ? 'cyan' : 'grey', fontSize: { xs: 25, sm: 22, md: 26, lg: 14 } }} onClick={handleFaqClick}>
                            FAQs
                        </Typography>

                    </form>
                </Box>

                <Divider sx={{ borderColor: 'white' }} />

                <Box
                    sx={{ bgcolor: 'white' }}
                    height="5vh"
                ></Box>

                <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    component="label"
                    sx={{ color: 'white', borderColor: 'white', fontSize: { xs: 30, sm: 22, md: 26, lg: 14 }, textAlign: 'center' }}
                    onClick={handleSignInClick}
                >
                    Sign In
                </Button>

            </Container>

        </Box>

    );
}

export default RightDrawer;