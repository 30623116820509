import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import Button from '@mui/material/Button';
import RightDrawer from './RightDrawer';
import Account from './Account';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";
import { FilterContext } from '../FilterContext';
import { useContext } from 'react';
import title from '../images/title.jpg';
import { useMediaQuery } from '@mui/material';

function HomeIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
        </SvgIcon>
    );
}

const TopBar = () => {
    const { isSignedIn, setIsSignedIn } = useContext(FilterContext);
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleHomeClick = () => navigate('/Home');
    const handleAboutClick = () => navigate('/About');
    const handleFaqClick = () => navigate('/Faq');
    const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

    const drawerContent = isSignedIn ?
        <Account isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} /> :
        <RightDrawer isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} />;

    const drawer = <Box sx={{ width: 225 }}>{drawerContent}</Box>;
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar sx={{ bgcolor: 'darkblue' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <nav style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src={title}
                            style={{ width: isMobile ? '20%' : '7%', height: 'auto', marginRight: '20px' }}
                        />
                    </nav>
                    <nav style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                        {isMobile ? (
                            <Button onClick={handleDrawerToggle} style={{ height: '90%' }}>
                                <MenuIcon sx={{ fontSize: 25, color: 'white' }} />
                            </Button>
                        ) : (
                            <>
                                <Button onClick={handleHomeClick} sx={{ marginRight: 2 }}>Home</Button>
                                <Button onClick={handleAboutClick} sx={{ marginRight: 2 }}>About</Button>
                                <Button onClick={handleFaqClick} sx={{ marginRight: 2 }}>FAQ</Button>
                            </>
                        )}
                        <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                            {drawer}
                        </Drawer>
                    </nav>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default TopBar;