import React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import title from '../images/title.jpg';

export default function AboutUs() {
    return (
        <Box
            sx={{
                bgcolor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >

            <Container maxWidth="md">
                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h2" component="h1" gutterBottom sx={{ color: 'white' }}>
                            About Us
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ color: 'white' }}>
                            At Valtre, we're passionate about empowering businesses to succeed in the digital age. With a team of experienced innovators and industry experts, we're dedicated to delivering cutting-edge solutions that drive growth, improve efficiency, and enhance customer experiences.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <img
                            src={title}
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom sx={{ color: 'white' }}>
                            Our Mission
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ color: 'white' }}>
                            Our mission is to revolutionize the way businesses operate by providing innovative, customer-centric solutions that unlock new opportunities for growth and success. We're committed to fostering a culture of innovation, collaboration, and continuous learning, and to making a positive impact on the communities we serve.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}