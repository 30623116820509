import React, { createContext } from 'react';
import { useState } from 'react';

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {

    const [language, setLanguage] = React.useState('English');
    const [isSignedIn, setIsSignedIn] = React.useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [checkoutDetails, setCheckoutDetails] = useState({});
    const [clientSecret, setClientSecret] = useState("");

    return (
        <FilterContext.Provider value={{
            isSignedIn, setIsSignedIn,
            language, setLanguage,
            userDetails, setUserDetails,
            checkoutDetails, setCheckoutDetails,
            clientSecret, setClientSecret,
        }}>
            {children}
        </FilterContext.Provider>
    );
};