import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { FilterContext } from '../FilterContext';
import { useContext } from 'react';

const Password = ({ }) => {

    const { checkoutDetails, setCheckoutDetails } = useContext(FilterContext);
    const navigate = useNavigate();

    const handleClick = (event) => {

        navigate('/Checkout', { state: { checkoutDetails } });
    };

    return (

        <Box component="main"

            sx={{ bgcolor: 'black' }}
        >

            <Container maxWidth="sm">

                <Box sx={{ width: '100%' }}>

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                        <Grid item xs={12}>

                            <Box
                                height="1vh"
                            ></Box>

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                <Grid item xs={12}>

                                    <Box sx={{ height: 'auto', width: '100%' }}>
                                        <Typography variant="h6" align="left" sx={{ color: 'white' }}>
                                            Create Password
                                        </Typography>

                                        <Box height="1vh" />

                                        <Typography variant="body1" align="left">
                                            Password
                                        </Typography>
                                        <TextField
                                            sx={{
                                                input: { color: 'blue' },
                                                label: { color: 'red' },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: 'red',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: 'blue',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'blue',
                                                    },
                                                    '& .MuiInputBase-input': {
                                                        backgroundColor: 'black',
                                                    },
                                                },
                                            }}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            autoCapitalize="off"
                                            spellCheck="false"
                                            fullWidth
                                            id="password"
                                            label="Password"
                                            type="password"
                                            variant="outlined"
                                            value={checkoutDetails.password}
                                            onChange={(event) => setCheckoutDetails({ ...checkoutDetails, password: event.target.value })}
                                        />
                                        <Box height="1vh" />

                                        <Typography variant="body1" align="left">
                                            Confirm Password
                                        </Typography>
                                        <TextField
                                            sx={{
                                                input: { color: 'blue' },
                                                label: { color: 'red' },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: 'red',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: 'blue',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'blue',
                                                    },
                                                    '& .MuiInputBase-input': {
                                                        backgroundColor: 'black',
                                                    },
                                                },
                                            }}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            autoCapitalize="off"
                                            spellCheck="false"
                                            fullWidth
                                            id="confirm-password"
                                            label="Confirm Password"
                                            type="password"
                                            variant="outlined"
                                            value={checkoutDetails.confirmPassword}
                                            onChange={(event) => setCheckoutDetails({ ...checkoutDetails, confirmPassword: event.target.value })}
                                        />

                                        <Box
                                            height="2vh"
                                        ></Box>

                                        <Button onClick={() => handleClick()} variant="contained" type="submit">
                                            Next
                                        </Button>
                                    </Box>

                                    <Box
                                        height="1vh"
                                    ></Box>

                                </Grid>

                            </Grid>

                            <Box
                                height="5vh"
                            ></Box>

                        </Grid>

                    </Grid>
                </Box>

            </Container>

        </Box>

    );
};

export default Password;