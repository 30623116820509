import React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useNavigate, Link } from "react-router-dom";
import Divider from '@mui/material/Divider';
import { FilterContext } from '../FilterContext';
import { useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';

const Account = ({ isSignedIn, setIsSignedIn }) => {

    const navigate = useNavigate();
    const { userDetails } = useContext(FilterContext);
    const username = userDetails.username;

    const handleClick = async (e) => {

        try {
            const response = await axios.post('http://localhost:9000/logout');

            if (response) {
                setIsSignedIn(false);
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('userDetails');
                navigate('/login');

            } else {
                setIsSignedIn(false);
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('userDetails');
                navigate('/login');
            }

        } catch (error) {
            setIsSignedIn(false);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('userDetails');
            navigate('/login');
        }
                
    };

    return (

        <Box
            sx={{ bgcolor: '#001a30' }}
        >

            <Box component="main"

                sx={{ bgcolor: '#ffffff', height: '70vh' }}
            >

                <Container maxWidth="lg">

                    <Box sx={{ width: '100%' }}>

                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                            <Grid item xs={12}>

                                <Box
                                    height="1vh"
                                ></Box>

                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                    <Grid item xs={12}>

                                        <Box sx={{ height: 'auto', width: '100%' }}>
                                            <form>

                                                <Typography variant="h6" align="left" sx={{ display: 'block', fontWeight: 'bold' }}>
                                                    My Account
                                                </Typography>

                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Avatar
                                                        alt="User Avatar"
                                                        src="https://via.placeholder.com/150" // replace with your image URL
                                                        sx={{ width: 100, height: 100, marginBottom: 2 }}
                                                    />
                                                </Box>

                                                <Link to="/AccountDetails">
                                                    <Typography variant="body" align="left" sx={{ display: 'block' }}>
                                                        {username}
                                                    </Typography>
                                                </Link>

                                                <Box
                                                    height="1vh"
                                                ></Box>

                                            </form>
                                        </Box>

                                        <Divider />

                                        <Box
                                            height="1vh"
                                        ></Box>

                                        <Box sx={{ height: 'auto', width: '100%' }}>
                                            <form>

                                                <Typography variant="h6" align="left" sx={{ display: 'block', fontWeight: 'bold' }}>
                                                    Home
                                                </Typography>

                                                <Link to="/CustomersMainPage">
                                                    <Typography variant="body" align="left" sx={{ display: 'block' }}>
                                                        Customers
                                                    </Typography>
                                                </Link>

                                                <Link to="/ProposalsMainPage">
                                                    <Typography variant="body" align="left" sx={{ display: 'block' }}>
                                                        Proposals
                                                    </Typography>
                                                </Link>

                                                <Link to="/WorkOrdersMainPage">
                                                    <Typography variant="body" align="left" sx={{ display: 'block' }}>
                                                        Work Orders
                                                    </Typography>
                                                </Link>

                                                <Link to="/InvoicesMainPage">
                                                    <Typography variant="body" align="left" sx={{ display: 'block' }}>
                                                        Invoices
                                                    </Typography>
                                                </Link>

                                                <Link to="/PurchasesMainPage">
                                                    <Typography variant="body" align="left" sx={{ display: 'block' }}>
                                                        Purchases
                                                    </Typography>
                                                </Link>

                                                <Link to="/ExpensesMainPage">
                                                    <Typography variant="body" align="left" sx={{ display: 'block' }}>
                                                        Expenses
                                                    </Typography>
                                                </Link>

                                                <Link to="/InventoryMainPage">
                                                    <Typography variant="body" align="left" sx={{ display: 'block' }}>
                                                        Inventory
                                                    </Typography>
                                                </Link>

                                                <Link to="/SuppliersMainPage">
                                                    <Typography variant="body" align="left" sx={{ display: 'block' }}>
                                                        Suppliers
                                                    </Typography>
                                                </Link>

                                            </form>
                                        </Box>

                                        <Box
                                            height="1vh"
                                        ></Box>

                                        <Box sx={{ height: 'auto', width: '100%' }}>
                                            <form>

                                                <Typography variant="h6" align="left" sx={{ display: 'block', fontWeight: 'bold' }}>
                                                    Support
                                                </Typography>

                                                <Link to="/Faq">
                                                    <Typography variant="body" align="left" sx={{ display: 'block' }}>
                                                        FAQs
                                                    </Typography>
                                                </Link>

                                                <Link to="/About">
                                                    <Typography variant="body" align="left" sx={{ display: 'block' }}>
                                                        About
                                                    </Typography>
                                                </Link>

                                            </form>
                                        </Box>

                                    </Grid>

                                </Grid>

                                <Divider />

                                <Box
                                    height="5vh"
                                ></Box>

                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    component="label"
                                    onClick={() => handleClick()}
                                >
                                    Sign Out
                                </Button>

                            </Grid>

                        </Grid>
                    </Box>

                </Container>

            </Box>

        </Box>


    );
}


export default Account;