import React from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';

import title from '../images/title.jpg';

const itemList = [
    { id: 1, name: 'iPhone' },
    { id: 2, name: 'iPad' },
    { id: 3, name: 'Samsung' },
    { id: 4, name: 'Google Pixel' },
    // Add more items to the list as needed
];

export default function LandingPage() {
    const navigate = useNavigate();

    const handleNavigate = (item) => {
        navigate(`/trade-in/${item.id}`, { state: { item } });
    };

    const faqs = [
        { id: 1, question: 'What is the process for trading in my device?', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum magna sed, convallis ex.' },
        { id: 2, question: 'How long does it take to receive my payout?', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum magna sed, convallis ex.' },
        // Add more FAQs to the list as needed
    ];

    return (
        <Box sx={{ marginTop: '3px' }}>
            <Container maxWidth="md">
                <Grid container spacing={4} sx={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h1" gutterBottom sx={{ color: 'black', textAlign: 'center' }}>
                            Get paid for your phone today!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <img
                            src={title}
                            alt="Home"
                            style={{ width: '100%', height: 'auto', margin: '0 auto' }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" component="h1" gutterBottom sx={{ color: 'black', textAlign: 'center' }}>
                            Find out how to make money from your iPhone, tablet, and get a payout.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            size="large"
                            sx={{ color: 'blue', bgcolor: 'white', margin: '0 auto' }}
                            onClick={() => navigate('/signup')}
                        >
                            Get Paid
                        </Button>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth="md">
                <Box sx={{ padding: '20px', marginTop: '40px', marginBottom: '40px' }}>
                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom>
                                Select your device to start your trade-in now
                            </Typography>
                        </Grid>
                        <Grid container spacing={2}>
                            {itemList.map((item) => (
                                <Grid item key={item.id} xs={12}>
                                    <Box sx={{
                                        padding: '20px',
                                        border: '1px solid #ddd',
                                        borderRadius: '10px',
                                        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                        marginBottom: '20px',
                                        cursor: 'pointer',
                                        display: 'block'
                                    }}
                                        onClick={() => handleNavigate(item)}
                                    >
                                        <Typography variant="body1" gutterBottom>
                                            {item.name}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                        Sign up and receive a bonus incentive on your offer +
                    </Typography>
                    <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

                </Box>
            </Container>

            <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

            <Container maxWidth="lg">
                <Box sx={{ backgroundColor: 'white', padding: '20px', marginTop: '40px', marginBottom: '40px' }}>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                        Our Process
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{
                                padding: '20px',
                                border: '3pt solid black',
                                borderRadius: '10px',
                                boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                margin: '20px'
                            }}>
                                <Typography variant="h8" gutterBottom>
                                    Step 1: Select Your Device
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum magna sed, convallis ex.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{
                                padding: '20px',
                                border: '3pt solid black',
                                borderRadius: '10px',
                                boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                margin: '20px'
                            }}>
                                <Typography variant="h8" gutterBottom>
                                    Step 2: Get a Quote
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum magna sed, convallis ex.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{
                                padding: '20px',
                                border: '3pt solid black',
                                borderRadius: '10px',
                                boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                margin: '20px'
                            }}>
                                <Typography variant="h8" gutterBottom>
                                    Step 3: Get Paid
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum magna sed, convallis ex.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

            <Container maxWidth="lg">
                <Box sx={{
                    backgroundColor: 'white',
                    padding: '20px',
                    marginTop: '40px',
                    marginBottom: '40px',
                    border: '1pt light black',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
                }}>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'white', bgcolor: 'lightblue' }}>
                        Our Process
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{
                                padding: '10px',
                                margin: '10px'
                            }}>
                                <Typography variant="h8" gutterBottom>
                                    Step 1: Select Your Device
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum magna sed, convallis ex.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{
                                padding: '10px',
                                margin: '10px'
                            }}>
                                <Typography variant="h8" gutterBottom>
                                    Step 2: Get a Quote
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum magna sed, convallis ex.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{
                                padding: '10px',
                                margin: '10px'
                            }}>
                                <Typography variant="h8" gutterBottom>
                                    Step 3: Get Paid
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum magna sed, convallis ex.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

            <Container maxWidth="lg">
                <Box sx={{
                    backgroundColor: 'lightgrey',
                    padding: '20px',
                    marginTop: '40px',
                    marginBottom: '40px',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
                }}>
                    <Typography variant="h6" gutterBottom sx={{ color: 'blue' }}>
                        What our clients have to say
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Here is a selection of testimonials from our satisfied clients.
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <Button variant="outlined" onClick={() => {/* Logic to navigate to previous quote */ }}>
                            Previous
                        </Button>
                        <Button variant="outlined" onClick={() => {/* Logic to navigate to next quote */ }}>
                            Next
                        </Button>
                    </Box>
                </Box>
            </Container>

            <Container maxWidth="lg">
                <Box sx={{
                    padding: '20px',
                    marginTop: '40px',
                    marginBottom: '40px',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
                }}>
                    <Typography variant="h4" gutterBottom >
                        FAQs
                    </Typography>
                    {faqs.map((faq) => (
                        <Accordion key={faq.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography variant="h6">
                                    {faq.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {faq.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Container>

            <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

            <Container maxWidth="lg" sx={{ backgroundImage: `url(${title})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', padding: '20px', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
                    <Typography variant="h3" gutterBottom sx={{ color: 'black' }}>
                        Get Started Today!
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ color: 'black' }}>
                        Click below and select your device now
                    </Typography>
                    <Button variant="contained" sx={{ backgroundColor: '#FFA07A', color: 'white', padding: '10px 20px' }}>
                        Get Paid
                    </Button>
                </Box>
            </Container>

            <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

            <Container maxWidth="xl" sx={{ backgroundColor: '#ADD8E6', padding: '20px', color: 'white' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} sx={{ marginBottom: '20px' }}>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Sell
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ color: 'white', textDecoration: 'underline', cursor: 'pointer' }}>
                            Sell Your Phone
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ color: 'white', textDecoration: 'underline', cursor: 'pointer' }}>
                            Sell Your Tablet
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ color: 'white', textDecoration: 'underline', cursor: 'pointer' }}>
                            Sell Your Laptop
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ marginBottom: '20px', textAlign: 'center' }}>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Contact Us
                        </Typography>
                        <Box sx={{ justifyContent: 'space-around' }}>
                            <FacebookIcon sx={{ color: 'white', cursor: 'pointer' }} />
                            <XIcon sx={{ color: 'white', cursor: 'pointer' }} />
                            <InstagramIcon sx={{ color: 'white', cursor: 'pointer' }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ marginBottom: '20px' }}>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                            About Us
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ color: 'white', textDecoration: 'underline', cursor: 'pointer' }}>
                            Our Story
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ color: 'white', textDecoration: 'underline', cursor: 'pointer' }}>
                            Our Team
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ color: 'white', textDecoration: 'underline', cursor: 'pointer' }}>
                            Our Mission
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

        </Box>
    );
}