import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import DashboardTiles from './DashboardTiles';

export default function Dashboard() {

    return (

        <Box component="main"
            sx={{ bgcolor: 'white' }}
        >

            <Container maxWidth="md">

                <Box sx={{ width: '100%' }}>

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                        <Grid item xs={12}>

                            <Box
                                height="1vh"
                            ></Box>

                            <DashboardTiles />

                        </Grid>

                            

                    </Grid>
                </Box>

                <Box
                    height="5vh"
                >
                </Box>

            </Container>

        </Box>


    );
}
