import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

export default function Cookies() {

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >

            <Box component="main"
                sx={{ bgcolor: '#ffffff' }}
            >

                <Container maxWidth="lg">

                    <Box>

                        <Box
                            height="1vh"
                        ></Box>

                        <Divider />

                        <Typography variant="h6" align="left">
                            Cookies
                        </Typography>

                        <Divider />

                    </Box>

                </Container>

            </Box>

        </Box>

    );
}